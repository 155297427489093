<template>
	<div class="page">
		<div class="page7" id="lxwm">
			<div class="lxwmk">
				<img src="../assets/img/lxwm.png" class="lxwm lxwmtp" ref="lxwmtp"/>
				<div class="lxwmsrk lxwmsrkdh" ref="lxwmsrkdh">
					<div class="lxwmbt">留下您的联系方式<br>我们承诺将在2个工作日内联系您</div>
					<div class="srkbt">事务所</div>
					<input class="sws" type="text" v-model="fromdata.sws"/>
					<div class="srkbt">职务</div>
					<select class="sws" v-model="fromdata.zw">
					  <option value ="合伙人">合伙人</option>
					  <option value ="审计经理">部门经理</option>
					  <option value="项目经理">审计经理</option>
					  <option value="质监部">质监部</option>
					  <option value="信息部">信息部</option>
					  <option value="技术部">技术部</option>
					  <option value="综合部">综合部</option>
					  <option value="其他部门">其他部门</option>
					</select>
					<div class="srkbt">姓名</div>
					<input class="sws" type="text" v-model="fromdata.xm"/>
					<div class="srkbt">手机号</div>
					<input class="sws"  type="text" v-model="fromdata.sjh"/>
					<div class="srkbt" type="text">联系时间</div>
					<div class="dxk">
						<div class="gzsj" :class="{ xz:gzsj }" @click="lxsj(1)">
							<div class="yd" :class="{ xzyd:gzsj }"></div>
							工作时间
						</div>
						<div class="gzsj" :class="{ xz:fgzsj }" @click="lxsj(2)" style="margin-right: 0;">
							<div class="yd" :class="{ xzyd:fgzsj }"></div>
							任意时间
						</div>
					</div>
					<div class="srkbt" type="text">您的问题</div>
					<div class="dxk">
						<div class="gzsj" :class="{ xz:azwt }" @click="ndwt(1)">
							<div class="yd" :class="{ xzyd:azwt }"></div>
							安装问题
						</div>
						<div class="gzsj" :class="{ xz:xszx }" @click="ndwt(2)" style="margin-right: 0;">
							<div class="yd" :class="{ xzyd:xszx }"></div>
							销售咨询
						</div>
					</div>
					<div class="xljdcpxxk" v-if="fromdata.wt == '安装问题'">
						<div class="xyljdcpxx" @click="bddxff(7)">
							<div class="dxyd" :class="{ dxydxz:azwtlb.ncbg }"></div>
							内存不够
						</div>
						<div class="xyljdcpxx" @click="bddxff(8)">
							<div class="dxyd" :class="{ dxydxz:azwtlb.azbl }"></div>
							安装不上
						</div>
						<div class="xyljdcpxx" @click="bddxff(9)">
							<div class="dxyd" :class="{ dxydxz:azwtlb.sjbl }"></div>
							升级不了
						</div>
						<div class="xyljdcpxx" @click="bddxff(10)">
							<div class="dxyd" :class="{ dxydxz:azwtlb.zcbl }"></div>
							注册不了
						</div>
					</div>
					<div class="xljdcpxxk" v-if="fromdata.wt == '销售咨询'">
						<div class="xyljdcpxx" @click="bddxff(2)">
							<div class="dxyd" :class="{ dxydxz:bddxxx.ssfzxx }"></div>
							网络表格及文档平台
						</div>
						<div class="xyljdcpxx" @click="bddxff(3)">
							<div class="dxyd" :class="{ dxydxz:bddxxx.hzzxxx }"></div>
							会计师事务所管理系统
						</div>
						<div class="xyljdcpxx" @click="bddxff(4)">
							<div class="dxyd" :class="{ dxydxz:bddxxx.dzdgxx }"></div>
							会计师事务所函证中心
						</div>
						<div class="xyljdcpxx" @click="bddxff(5)">
							<div class="dxyd" :class="{ dxydxz:bddxxx.czcqgj }"></div>
							取数查账抽凭工具
						</div>
						<div class="xyljdcpxx" @click="bddxff(6)">
							<div class="dxyd" :class="{ dxydxz:bddxxx.bgtxgj }"></div>
							报告赋码自动填写工具
						</div>
					</div>
					<div class="tj" v-if="tjqny == 0" @click="lxwm()">提交</div>
					<div  class="tj1" v-if="tjqny == 1">{{tjants}}</div>
					<div class="btts" :class="{ bttsbxs: bttsxs }">{{btss}}</div>
				</div>
			</div>
		</div>
		<div class="foot">
			<a href="https://beian.miit.gov.cn/" target="_blank">滇ICP备2021002230号-1</a><br>
			北京独木成林科技有限公司<br>
			Beijing One Tree Forest Technology Co., Ltd.
		</div>
	</div>
</template>

<style scoped>
.dxyd{
	width: 28px;
	height: 28px;
	border-radius: 14px;
	border: 1px solid #68747f;
	position: absolute;
	left: 30px;
	top: 16px;
	box-sizing: border-box;
}
.dxydxz{
	border: 10px solid #05ac73;
}
.xyljdcpxx{
	width: 100%;
	height: 60px;
	background: #f8fafc;
	margin-bottom: 20px;
	font-size: 21px;
	color: #616076;
	display: flex;
	justify-content: left;
	align-items: center;
	box-sizing: border-box;
	padding-left: 80px;
	position: relative;
}
.xljdcpxxk{
	width: 100%;
	margin-top: 20px;
}
.wlhnrdy{
	font-size: 26px;
	color: #68747f;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 55px;
	line-height: 40px;
}
.wlhnrdy1{
	font-size: 26px;
	color: #68747f;
	text-align: center;
	margin-top: 50px;
	margin-bottom: 55px;
	line-height: 40px;
}
.wlhbtdy{
	font-size: 28px;
	line-height: 45px;
	color: #3e464c;
	text-align: center;
	font-weight: bold;
}
.gzdy{
	display: block;
	width: 400px;
	margin: 0 auto;
	margin-top: 50px;
	box-shadow: 0px 0px 43px 0px rgba(6, 0, 1, 0.1);
}
.page8{
	width: 100%;
	box-sizing: border-box;
	padding-left: 43px;
	padding-right: 43px;
	overflow: hidden;
	padding-bottom: 70px;
	padding-top: 70px;
}
.foot{
	width: 100%;
	background: #f5f9fa;
	margin-top: 50px;
	color: #68747f;
	line-height: 30px;
	text-align: center;
	font-size: 18px;
	padding-top: 20px;
	padding-bottom: 20px;
}
.foot a{
	color: #68747f!important;
}
.tj{
	height: 60px;
	width: 100%;
	background: #05ac73;
	border-radius: 10px;
	margin-top: 50px;
	font-size: 21px;
	color: #FFFFFF;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}
.yd{
	width: 21px;
	height: 21px;
	border-radius: 10.5px;
	border: 1px solid #68747f;
	position: absolute;
	left: 15px;
	top: 18px;
	box-sizing: border-box;
}
.gzsj{
	height: 100%;
	box-sizing: border-box;
	padding-left: 50px;
	padding-right: 15px;
	position: relative;
	color: #68747f;
	font-size: 21px;
	text-align: center;
	border: 1px solid #e0e0e0;
	float: left;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	margin-right: 25px;
}
.xz{
	border-color: #05ac73;
	color: #05ac73;
}
.xzyd{
	border: 8px solid #05ac73;
}
.dxk{
	height: 60px;
	width: 100%;
	margin-top: 20px;
}
.sws .xlysx{
	width: 429px;
}
.sws:focus{
	outline:none;
	border: 1px solid #05ac73;
}
.sws{
	width: 100%;
	height: 60px;
	background: #FFFFFF;
	border: 1px solid #e0e0e0;
	font-size: 21px;
	color: #616076;
	box-sizing: border-box;
	padding-left: 20px;
	padding-right: 20px;
	line-height: 58px;
	margin-top: 20px;
}
.srkbt{
	width: 100%;
	font-size: 23px;
	color: #616076;
	text-align: left;
	margin-top: 30px;
}
.lxwmbt{
	width: 100%;
	font-size: 25px;
	color: #68747f;
	text-align: center;
	line-height: 40px;
}
.lxwmsrk{
	width: 454px;
	background: #FFFFFF;
	background-image: url(../assets/img/lxwmbg.png);
	background-repeat: no-repeat;
	background-size: 454px 103px;
	background-position: left 20px;
	margin: 0 auto;
	position: relative;
	z-index: 1;
	margin-top: -82px;
	box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.15);
	box-sizing: border-box;
	padding-top: 50px;
	padding-left: 25px;
	padding-right: 25px;
	padding-bottom: 50px;
}
.lxwm{
	width: 100%;
	display: block;
}
.lxwmk{
	width: 100%;
}
.page7{
	width: 100%;
	margin-bottom: 50px;
}
.btts{
	width: 100%;
	font-size: 23px;
	color: #F56C6C;
	text-align: center;
	margin-top: 40px;
}
.bttsbxs{
	display: none;
}
.tj1{
	height: 60px;
	width: 100%;
	background: #EBEEF5;
	border-radius: 10px;
	margin-top: 50px;
	font-size: 21px;
	color: #909399;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>

<script>
import wxapi from './yd/wxapi.js';
export default {
	name: 'contact',
	data () {
		return {
			azwt: false,
			xszx: false,
			gzsj: true,
			fgzsj: false,
			btss:'请输入姓名',
			bttsxs:true,
			tjqny:0,
			tjants:'提交中',
			bddxxx:{
				ssfzxx:false,
				hzzxxx:false,
				dzdgxx:false,
				czcqgj:false
			},
			azwtlb:{
				ncbg:false,
				azbl:false,
				sjbl:false,
				zcbl:false
			},
			fromdata:{
			  sws: '',
			  zw:'',
			  xm: '',
			  sjh: '',
			  sj: '工作时间',
			  lj:'',
			  wt:''
			},
			ly:'yd',
			tjr:''
		}
	},
	mounted () {
		let that = this;
		if(that.$route.params.id){
			that.tjr = that.$route.params.id;
		}
		let url = encodeURIComponent(location.href.split('#')[0]);
		let dz = window.location.href;
		this.$http('/wxjsapi?url=' + url).then(function (response) {
			let data = response.data;
			let option = {
				title: "榕树万千李金鹏",
				desc: "留下您的联系方式,我们承诺将在2个工作日内联系您。",
				link: dz,
				imgUrl: "https://www.dmclkj.com/slt.png",
			};
			wxapi.wxRegister(data,option);
		}).catch(function (error) {
		});
		let broser = window.navigator.userAgent.toLowerCase();
		if(broser.match(/MicroMessenger/i) == 'micromessenger'){
		     that.ly = "wx";
		}
		this.$http('/browse?source=' + that.ly + '&tjr=' + that.tjr).then(function (response) {
			
		}).catch(function (error) {
			
		});
	},
	methods: {
		lxsj(lx){
			let that = this;
			if(lx == 1){
				that.gzsj = true;
				that.fgzsj = false;
				that.fromdata.sj = "工作时间";
			}else{
				let that = this;
				that.gzsj = false;
				that.fgzsj = true;
				that.fromdata.sj = "任意时间";
			}
			that.bddxxx = {
				ssfzxx:false,
				hzzxxx:false,
				dzdgxx:false,
				czcqgj:false,
				bgtxgj:false
			}
			that.azwtlb = {
				ncbg:false,
				azbl:false,
				sjbl:false,
				zcbl:false
			}
		},
		ndwt(lx){
			let that = this;
			if(lx == 1){
				that.azwt = true;
				that.xszx = false;
				that.fromdata.wt = "安装问题";
			}else{
				that.azwt = false;
				that.xszx = true;
				that.fromdata.wt = "销售咨询";
			}
			that.bddxxx = {
				ssfzxx:false,
				hzzxxx:false,
				dzdgxx:false,
				czcqgj:false,
				bgtxgj:false
			}
			that.azwtlb = {
				ncbg:false,
				azbl:false,
				sjbl:false,
				zcbl:false
			}
		},
		bddxff(xx){
			let that = this;
			if(xx == 2){
				if(that.bddxxx.ssfzxx == false){
					that.bddxxx.ssfzxx = true;
				}else{
					that.bddxxx.qbxx = false;
					that.bddxxx.ssfzxx = false;
				}
			}
			if(xx == 3){
				if(that.bddxxx.hzzxxx == false){
					that.bddxxx.hzzxxx = true;
				}else{
					that.bddxxx.qbxx = false;
					that.bddxxx.hzzxxx = false;
				}
			}
			if(xx == 4){
				if(that.bddxxx.dzdgxx == false){
					that.bddxxx.dzdgxx = true;
				}else{
					that.bddxxx.dzdgxx = false;
				}
			}
			if(xx == 5){
				if(that.bddxxx.czcqgj == false){
					that.bddxxx.czcqgj = true;
				}else{
					that.bddxxx.czcqgj = false;
				}
			}
			if(xx == 6){
				if(that.bddxxx.bgtxgj == false){
					that.bddxxx.bgtxgj = true;
				}else{
					that.bddxxx.bgtxgj = false;
				}
			}
			if(xx == 7){
				if(that.azwtlb.ncbg == false){
					that.azwtlb.ncbg = true;
				}else{
					that.azwtlb.ncbg = false;
				}
			}
			if(xx == 8){
				if(that.azwtlb.azbl == false){
					that.azwtlb.azbl = true;
				}else{
					that.azwtlb.azbl = false;
				}
			}
			if(xx == 9){
				if(that.azwtlb.sjbl == false){
					that.azwtlb.sjbl = true;
				}else{
					that.azwtlb.sjbl = false;
				}
			}
			if(xx == 10){
				if(that.azwtlb.zcbl == false){
					that.azwtlb.zcbl = true;
				}else{
					that.azwtlb.zcbl = false;
				}
			}
		},
		lxwm(){
			let that = this;
			that.fromdata.lj = '';
			if(that.fromdata.wt == '安装问题'){
				if(that.azwtlb.ncbg == true){
					that.fromdata.lj = that.fromdata.lj + '内存不够';
				}
				if(that.azwtlb.azbl == true){
					if(that.fromdata.lj == ''){
						that.fromdata.lj = that.fromdata.lj + '安装不上';
					}else{
						that.fromdata.lj = that.fromdata.lj + '，安装不上';
					}
				}
				if(that.azwtlb.sjbl == true){
					if(that.fromdata.lj == ''){
						that.fromdata.lj = that.fromdata.lj + '升级不了';
					}else{
						that.fromdata.lj = that.fromdata.lj + '，升级不了';
					}
				}
				if(that.azwtlb.zcbl == true){
					if(that.fromdata.lj == ''){
						that.fromdata.lj = that.fromdata.lj + '注册不了';
					}else{
						that.fromdata.lj = that.fromdata.lj + '，注册不了';
					}
				}
			}else if(that.fromdata.wt == '销售咨询'){
				if(that.bddxxx.ssfzxx == true){
					that.fromdata.lj = that.fromdata.lj + '网络表格及文档平台';
				}
				if(that.bddxxx.hzzxxx == true){
					if(that.fromdata.lj == ''){
						that.fromdata.lj = that.fromdata.lj + '会计师事务所管理系统';
					}else{
						that.fromdata.lj = that.fromdata.lj + '，会计师事务所管理系统';
					}
				}
				if(that.bddxxx.dzdgxx == true){
					if(that.fromdata.lj == ''){
						that.fromdata.lj = that.fromdata.lj + '会计师事务所函证中心';
					}else{
						that.fromdata.lj = that.fromdata.lj + '，会计师事务所函证中心';
					}
				}
				if(that.bddxxx.czcqgj == true){
					if(that.fromdata.lj == ''){
						that.fromdata.lj = that.fromdata.lj + '取数查账抽凭工具';
					}else{
						that.fromdata.lj = that.fromdata.lj + '，取数查账抽凭工具';
					}
				}
				if(that.bddxxx.bgtxgj == true){
					if(that.fromdata.lj == ''){
						that.fromdata.lj = that.fromdata.lj + '报告赋码自动填写工具';
					}else{
						that.fromdata.lj = that.fromdata.lj + '，报告赋码自动填写工具';
					}
				}
			}
			if(that.fromdata.lj==''){
				that.btss = '请选择您的问题！';
				that.bttsxs = false;
			}else if(that.fromdata.sws == '' || that.fromdata.zw == '' || that.fromdata.sjh == '' || that.fromdata.xm == '' || that.fromdata.wt == ''){
				that.btss = '请填写完整信息！';
				that.bttsxs = false;
			}else if(!(/^1[3456789]\d{9}$/.test(this.fromdata.sjh))){
				that.btss = '请确认手机号是否正确！';
				that.bttsxs = false;
			}else{
				that.btss = '';
				that.bttsxs = true;
				that.tjqny = 1;
				that.tjants = '提交中';
				this.$http.post('/contact', {
					wt: that.fromdata.wt,
					  sws: that.fromdata.sws,
					  zw:that.fromdata.zw,
					  xm: that.fromdata.xm,
					  sjh:that.fromdata.sjh,
					  sj:that.fromdata.sj,
					  lj:that.fromdata.lj,
					  ly:that.ly,
					  tjr:that.tjr
				  })
				  .then(function (response) {
					  if(response.data.errmsg=='ok'){
						  alert('提交成功！')
						  that.tjants = '提交成功';
						  that.fromdata.sws = '';
						  that.fromdata.zw = '';
						  that.fromdata.xm = '';
						  that.fromdata.sjh = '';
						  that.fromdata.lj = '';
					  }else{
						  alert('网络异常，请再次提交！')
						  that.btss = '';
						  that.tjqny = 0;
						  that.bttsxs = false;
					  }
				  }).catch(function (error) {
				    alert('网络异常，请再次提交！')
					that.btss = '';
					that.tjqny = 0;
					that.bttsxs = false;
				  });
			}
		}
	}
};
</script>
